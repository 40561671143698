import React from 'react';
import { styled, css } from 'styled-components';
import { colors } from '../../../constants/colors';
import LoadingSpinner from 'components/common/LoadingSpinner';
interface IProps {
  btnText: string;
  btnWidth: number;
  position?: string;
  onClose: () => void;
  onClick?: () => void;
  isLoading?: boolean;
  isValid?: boolean;
}

const ModalButton: React.FC<IProps> = ({
  btnText,
  btnWidth,
  position,
  isLoading,
  onClose,
  onClick,
  isValid = true,
}) => {
  return (
    <Wrap $position={position}>
      <MyButton type="button" $btnWidth={btnWidth} onClick={onClose}>
        취소
      </MyButton>
      <MyButton
        type="submit"
        $btnWidth={btnWidth}
        $isFill
        onClick={onClick}
        disabled={!isValid}
      >
        {isLoading ? <LoadingSpinner size={14} /> : btnText}
      </MyButton>
    </Wrap>
  );
};

const getBtnStyle = ($isFill?: boolean) => {
  if ($isFill) {
    return css`
      background-color: ${({ theme }) => theme.PRIMARY};
      border: 1px solid ${({ theme }) => theme.PRIMARY};
      color: ${colors.WHITE};
      &:hover {
        background-color: ${({ theme }) => theme.PRIMARY}E5;
        border: 1px solid ${({ theme }) => theme.PRIMARY}E5;
      }
      &:active {
        background-color: ${({ theme }) => theme.PRIMARY}E5;
        border: 1px solid ${({ theme }) => theme.PRIMARY}E5;
      }
    `;
  }
  return css`
    border: 1px solid ${colors.NEUTRAl_200};
    background-color: ${colors.WHITE};
    color: ${({ theme }) => theme.PRIMARY};
    &:hover {
      background-color: ${colors.NEUTRAl_50};
      color: ${({ theme }) => theme.PRIMARY}E5;
    }
    &:active {
      background-color: ${colors.NEUTRAl_50};
      color: ${({ theme }) => theme.PRIMARY}E5;
    }
  `;
};

const Wrap = styled.div<{ $position?: string }>`
  display: flex;
  gap: 12px;
  justify-content: ${({ $position }) =>
    $position ? $position : 'space-between'};
  width: 100%;
`;

const MyButton = styled.button<{
  $btnWidth: number;
  $isFill?: boolean;
  disabled?: boolean;
}>`
  border-radius: 6px;
  border: 0;
  outline: 0;
  width: ${({ $btnWidth }) => $btnWidth}px;
  padding: 12px 24px;
  ${({ $isFill }) => getBtnStyle($isFill)};
  font-family: 'Pretendard_B';
  font-size: 16px;

  // 비활성화 상태일 때 스타일 추가
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${colors.NEUTRAl_300};
      border-color: ${colors.NEUTRAl_300};
      color: ${({ theme }) => theme.TEXT_SECONDARY};
      cursor: not-allowed;

      &:hover {
        background-color: ${colors.NEUTRAl_300};
        border-color: ${colors.NEUTRAl_300};
      }
      &:active {
        background-color: ${colors.NEUTRAl_300};
        border-color: ${colors.NEUTRAl_300};
      }
    `}
`;

export default ModalButton;
