import React from 'react';
import { useAppSelect } from 'store/configureStore.hooks';
import { ThemeProvider, createTheme } from '@mui/material/styles';
interface IProps {
  children: React.ReactNode;
}
const MuiProvider: React.FC<IProps> = ({ children }) => {
  const themeInfo = useAppSelect((state) => state.theme);
  const selectedMode = themeInfo.data.mode === 'darkTheme' ? 'dark' : 'light';

  return (
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: selectedMode,
        },
      })}
    >
      {children}
    </ThemeProvider>
  );
};

export default MuiProvider;
