import { colors } from 'constants/colors';

export const tooltipLocation = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -10], // 첫 번째 값은 수평 방향의 오프셋, 두 번째 값은 수직 방향의 오프셋
      },
    },
  ],
};

export const notifyAllOff = {
  color: `${colors.ALERT}`,
  position: 'fixed',
  bottom: '26px',
  right: '10px',
  zIndex: '10001',
  backgroundColor: 'black',
  borderRadius: '50%',
  width: '32px',
  height: '32px',
  cursor: 'pointer',
};
