import { useQuery } from '@tanstack/react-query';
import { getNavInfo } from 'apis/dashboard-rest';
import { queryKeys } from 'services/queryKeys';
import store from 'store/configureStore';
export const useNav = () => {
  const companyId = store.getState().accountInfo.info.companyId;
  return useQuery([queryKeys.ENTERPRISE, companyId], () =>
    getNavInfo(companyId),
  );
};
