import React from 'react';
import styled, { keyframes } from 'styled-components';
interface IProps {
  size: number;
}
const LoadingSpinner: React.FC<IProps> = ({ size }) => {
  return (
    <LoadingWrap>
      <Spinner $size={size} />
    </LoadingWrap>
  );
};

const LoadingWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-left: 4px;
  background-color: transparent;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<{ $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  border: 1.5px solid lightgrey;
  border-top: 1.5px solid black;
  border-radius: 50%;
  animation: ${spin} 1s linear infinite;
`;

export default LoadingSpinner;
