import { createGlobalStyle, css } from 'styled-components';
import reset from 'styled-reset';
import { colors } from '../constants/colors';

const GlobalCss = createGlobalStyle`
  ${reset};
  * {
    box-sizing: border-box;
  }

  body {
    background-color: ${({ theme }) => theme.ROOT_BG};
    font-family: 'PretendardVariable';
    font-size: 15px;
    letter-spacing: 0.3px;
  }
  
  input {
    font-family: 'PretendardVariable';
    letter-spacing: 0.3px;
    outline: 0;
    border: 1px solid light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  }

  textarea {
    outline: 0;
    resize: none;
  }

  select {
    font-family: 'PretendardVariable';
    letter-spacing: 0.3px;
  }

  button {
    font-family: 'PretendardVariable';
    letter-spacing: 0.3px;
    cursor: pointer;
    transition: ease-in-out 0.2s;
  }
  
  button:active:not([class*="Mui"]) {
    scale: 0.93;
    transition: ease-in-out 0.15s;
  }

  .notice {
    width: 500px;
    /* background: #1b2845dd; */
    background: ${({ theme }) => theme.CARD_BG_PRIMARY};
    box-shadow: 0 0 15px 0 ${({ theme }) => theme.TEXT_PRIMARY}13;
    color: ${({ theme }) => theme.TEXT_PRIMARY};
  }
  
  .file-notice {
    svg {
      fill: ${({ theme }) => theme.TEXT_PRIMARY};
    }
    .ant-notification-notice-description {
      color: ${({ theme }) => theme.TEXT_PRIMARY} !important;
    }
  }

  .ant-notification-notice-message {
    color: ${({ theme }) => theme.TEXT_PRIMARY} !important;
    /* 원하는 색상 코드로 변경 */
  }
  
  [class*="Mui"], [class*="ant"] {
    font-family: 'PretendardVariable' !important;
  }
  
  [class*="highchart"] text {
    font-family: 'PretendardVariable' !important;
  }
`;

export const defaultCardCss = css`
  background: ${({ theme }) => theme.CARD_BG_PRIMARY};
  box-shadow: 0 0px 20px 13px ${({ theme }) => theme.TEXT_PRIMARY}03;
  border: 0;
  margin-bottom: 30px;
  border-radius: 8px;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  padding: 18px 24px;
`;

export default GlobalCss;
