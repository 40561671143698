import React, { useState } from 'react';

const useDeleteTextCheck = (text: string) => {
  const [inputText, setInputText] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputText(value);
    console.log(value, text);
    setIsValid(value === text);
  };

  return {
    inputText,
    isValid,
    handleInputChange,
  };
};

export default useDeleteTextCheck;
