import { dark, light } from '@mui/material/styles/createPalette';
import zIndex from '@mui/material/styles/zIndex';
import { ThemeInfo } from 'store/modules/theme';

export const primaryColors = [
  '#436ddb',
  '#ac389e',
  '#2CAC2A',
  '#34b5bd',
  '#525eeb',
];

export const createLightTheme = (themeInfo: ThemeInfo) => ({
  ROOT_BG: '#F4F6F9',
  CARD_BG_PRIMARY: '#FFFFFF',
  CARD_BG_SECONDARY: '#EDF0FF',
  CARD_SHADOW: '',
  CARD_BUTTON_BG: '#F2F3F5',
  SIDEBAR_BG: '#FFFFFF',
  TEXT_PRIMARY: '#4e5968',
  TEXT_SECONDARY: '#797979',
  SELECT_PRIMARY: '#f8f9fb',
  SELECT_SECONDARY: '#F3F5FF',
  SETTING_PRIMARY: '#b4b7c2',
  BUTTON_PRIMARY: '#4079e7',
  BUTTON_SECONDARY: '#f2f3f5',
  BUTTON_SECONDARY_COLOR: '#67696c',
  BUTTON_THIRD: '#7f7f7f',
  WHITE: '#ffffff',
  BLACK: '#000',
  MODE_CHANGE_BG: '#e5e7eb',
  MODE_CHANGE_COLOR: '#9ca3af',
  TABLE_HEADER_BG: '#6d6d6d',
  TABLE_TH_COLOR: '#ffffff',
  TABLE_TR_COLOR1: '#f1f1f1',
  TABLE_TR_COLOR2: '#f1f1f1',
  CHART_CARD_BG: '#eceffe',
  PRIMARY: themeInfo.data.primary,
});

export const createDarkTheme = (themeInfo: ThemeInfo) => ({
  ROOT_BG: '#1b1b2a',
  CARD_BG_PRIMARY: '#2c2e41',
  CARD_BG_SECONDARY: '#EDF0FF',
  CARD_SHADOW: '',
  CARD_BUTTON_BG: '#565867',
  SIDEBAR_BG: '#FFFFFF',
  TEXT_PRIMARY: '#ffffff',
  TEXT_SECONDARY: '#797979',
  SELECT_PRIMARY: '#6b6b74  ',
  SELECT_SECONDARY: '#F3F5FF',
  SETTING_PRIMARY: '#b4b7c2',
  BUTTON_PRIMARY: '#4079e7',
  BUTTON_SECONDARY: '#565867',
  BUTTON_SECONDARY_COLOR: '#eeeeee',
  BUTTON_THIRD: '#676b73',
  WHITE: '#ffffff',
  BLACK: '#000',
  MODE_CHANGE_BG: '#0f337b',
  MODE_CHANGE_COLOR: '#facc15',
  TABLE_HEADER_BG: '#27293d',
  TABLE_TH_COLOR: '#ffffff',
  TABLE_TR_COLOR1: '#2c3546',
  TABLE_TR_COLOR2: '#2c3546',
  CHART_CARD_BG: '#262631',
  PRIMARY: adjustColorForDarkMode(themeInfo.data.primary),
});

export const createTheme = (themeInfo: ThemeInfo) => ({
  lightTheme: createLightTheme(themeInfo),
  darkTheme: createDarkTheme(themeInfo),
});

export const colors = {
  SCREEN_BG: '#1b1b2a',
  BOX_BG: '#27293d',
  GREEN: '#62BD69',
  BLUE_BASE: '#436DDB',
  BLUE_LIGHT: '#698ae2',
  BLUE_DARK: '#3657af',
  TABLE_HEADER: 'rgb(181, 181, 182)',
  ALERT: '#DD4949',
  WHITE: '#ffffff',
  BLACK: '#000000',
  NEUTRAl_50: '#F5F6FA',
  NEUTRAl_100: '#E7EAEE',
  NEUTRAl_200: '#D0D5DD',
  NEUTRAl_300: '#B8C0CC',
  NEUTRAl_400: '#8A92A6',
  NEUTRAl_500: '#64748B',
  NEUTRAl_600: '#4B5768',
  NEUTRAl_700: '#323A46',
  NEUTRAl_800: '#191D23',
  NEUTRAl_900: '#0D0F11',
  BUTTON_BORDER_COLOR: '#1d8cf8',
};

const adjustColorForDarkMode = (color: string): string => {
  // 색상 코드가 '#ffffff' 형식인지 확인
  if (/^#[0-9A-Fa-f]{6}$/.test(color)) {
    // RGB 값을 추출
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // 각 색상 값을 20% 어둡게 만듦
    const darkenFactor = 0.8;
    const newR = Math.round(r * darkenFactor);
    const newG = Math.round(g * darkenFactor);
    const newB = Math.round(b * darkenFactor);

    // 새로운 색상 코드 반환
    return `#${newR.toString(16).padStart(2, '0')}${newG
      .toString(16)
      .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
  }

  // 색상 코드가 올바른 형식이 아니면 원래 색상 반환
  return color;
};

// color를 커스텀 훅으로 light, dark모드를 구분하여 상태값으로 넣어놓고
// 각 스타일에 theme을 부여
// 현재, 속성명 및 컬러가 무분별하게 사용되어 하나로 합치는 작업이 어렵고 의미가 없음
// 새로 디자인 한 컬러를 기준으로 속성명과 값을 작업하는 것이 좋을 것 같음
// 컬러가 필요한 곳에 커스텀 훅을 불러와서 theme을 적용
