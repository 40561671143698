import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type confirm = {
  isConfirm: boolean;
  message: string;
};

const initialState = {
  data: {
    isConfirm: false,
    message: '',
  },
};

const confirmSlice = createSlice({
  name: 'confirm',
  initialState,
  reducers: {
    setConfirmData: (state, action: PayloadAction<confirm>) => {
      state.data = { ...state.data, ...action.payload };
    },
    setResetConfirm: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setConfirmData, setResetConfirm } = confirmSlice.actions;
export default confirmSlice.reducer;
