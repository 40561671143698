import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  isRemote: false,
};

const remoteSlice = createSlice({
  name: 'remoteControl',
  initialState,
  reducers: {
    setIsRemote: (state, action: PayloadAction<boolean>) => {
      state.isRemote = action.payload;
    },
    setResetRemote: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setIsRemote, setResetRemote } = remoteSlice.actions;
export default remoteSlice.reducer;
