import { tagColors } from 'constants/tag-colors';
import React from 'react';
import { cvtAlarmStatus, cvtAlarmType } from 'services/miscFunc';
import styled from 'styled-components';
import { readableColor } from 'polished';
interface IProps {
  text: string;
  color?: string;
}

const Tag: React.FC<IProps> = ({ text, color }) => {
  const getColor = () => {
    const hasKey = Object.keys(tagColors)?.includes(text);
    if (color) {
      return color;
    }
    if (text && hasKey) {
      return tagColors[text];
    }
    return '#ACACAC';
  };

  return (
    <TagSpan color={getColor()}>
      {cvtAlarmStatus(text) || cvtAlarmType(text) || text}
    </TagSpan>
  );
};

const TagSpan = styled.span<{ color: string }>`
  background: ${({ color }) => color};
  padding: 5px;
  color: ${({ color }) => readableColor(color)} !important;
  border-radius: 4px;
`;

export default Tag;
