/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from 'components/common/Modal';
import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { getDefaultWrap } from './common/modalCss';
import { colors } from 'constants/colors';
import { useNav } from 'apiHooks/useNav';
import ModalTitle from './common/ModalTitle';
import AlarmBasicInfo from 'components/AlarmBasicInfo/AlarmBasicInfo';
import AlarmConfirmMessage from 'components/AlarmConfirmMessage/AlarmConfirmMessage';
import AlarmEnteredList from './AlarmEnteredList/AlarmEnteredList';
import { useAlarmDetail, useMutationControlAllAlarms } from 'apiHooks/useAlarm';
import { INIT_ALARM_DETAIL } from 'services/init-data';
import { Tooltip, Typography } from '@mui/material';
import { apiCompleted } from 'apis/common';
import ConfirmModal from './ConfirmModal';
import { customErrorMsg } from 'services/miscFunc';

interface IProps {
  data: any;
  refetch?: () => void;
  onClose: () => void;
}

const AlarmDetailModal: React.FC<IProps> = ({ data, refetch, onClose }) => {
  const [onAllSensorConfirmModal, setOnAllSensorConfirmModal] = useState(false);
  const { data: domainList } = useNav();
  const { data: detailData = INIT_ALARM_DETAIL, refetch: detailRefetch } =
    useAlarmDetail(data.id);
  const { mutateAsync: mutateAlarmControl, isLoading } =
    useMutationControlAllAlarms();

  const handleClickAllControlSensorAlarms = async () => {
    const res = await mutateAlarmControl(data.sensor.id);
    if (res?.success) {
      const successCount = res.data.successCount;
      setOnAllSensorConfirmModal(false);
      if (successCount > 0) {
        apiCompleted(
          `미확인 상태인 ${res.data.successCount}개의 알람이 모두 확인 처리 되었습니다.`,
        );
        refetch?.();
        return;
      }
      customErrorMsg('일괄 적용 될 알람이 존재하지 않습니다.');
    }
  };
  const isSensorAlarm = !!data.sensor?.id;

  return (
    <Modal>
      <Wrap>
        <ModalTitle
          title="상세정보"
          onClose={onClose}
          textColor="#9a9a9a"
          fillColor="#9a9a9a"
        />
        <ContentWrap>
          <ListWrap>
            <h2>· 알람 정보</h2>
            <AlarmBasicInfo data={data} />
          </ListWrap>
          <ListWrap>
            <h2>· 내용 등록</h2>
            <AlarmConfirmMessage
              data={data}
              refetch={refetch}
              detailRefetch={detailRefetch}
            />
          </ListWrap>
          <ListWrap>
            <h2>· 내용 목록</h2>
            <AlarmEnteredList data={detailData} />
          </ListWrap>
          {isSensorAlarm && (
            <Tooltip
              title={
                <Typography sx={{ fontSize: '13px' }}>
                  해당 센서의 모든 알람 미확인 → 확인 적용
                </Typography>
              }
              arrow
              placement="top"
            >
              <ConfirmBtn onClick={() => setOnAllSensorConfirmModal(true)}>
                알람 일괄 적용
              </ConfirmBtn>
            </Tooltip>
          )}
          <ModalBtn onClick={onClose}>닫기</ModalBtn>
        </ContentWrap>
      </Wrap>

      {onAllSensorConfirmModal && (
        <ConfirmModal
          text={`${data.sensor.name} 센서의 미확인 알람들을 확인으로 일괄 적용 하시겠습니까?`}
          isLoading={isLoading}
          onClick={handleClickAllControlSensorAlarms}
          onClose={() => setOnAllSensorConfirmModal(false)}
        />
      )}
    </Modal>
  );
};

const Wrap = styled.div`
  ${getDefaultWrap};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  width: 700px;
  height: 750px;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 100%;
  color: ${({ theme }) => theme.TEXT_PRIMARY};

  h2 {
    font-size: 18px;
    font-weight: 600;
  }
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`;

const ModalBtn = styled.button`
  position: absolute;
  bottom: 20px;
  width: 80px;
  padding: 8px 24px;
  align-self: flex-end;
  color: ${({ theme }) => theme.WHITE};
  background: ${({ theme }) => theme.BUTTON_THIRD};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 500;
`;

const ConfirmBtn = styled.button`
  position: absolute;
  bottom: 20px;
  right: 120px;
  border: 0;
  outline: 0;
  padding: 8px 24px;
  border-radius: 6px;
  background: ${({ theme }) => theme.PRIMARY};
  /* background: linear-gradient(to bottom left, #1d8cf8, #3358f4, #1d8cf8); */
  color: ${colors.WHITE};
  font-weight: 500;
`;

export default AlarmDetailModal;
