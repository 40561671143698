import Modal from 'components/common/Modal';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import ModalTitle from './common/ModalTitle';
import { ReactComponent as confirmSVG } from 'assets/img/confirm.svg';
import { useDispatch } from 'react-redux';
import { setResetConfirm } from 'store/modules/confirm';
import { useAppSelect } from 'store/configureStore.hooks';

const CompleteModal = () => {
  const { isConfirm, message } = useAppSelect(
    (state) => state.confirmData.data,
  );
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setResetConfirm());
  };

  if (!isConfirm) return null;

  return (
    <Modal onClose={handleClose}>
      <Wrap>
        <ModalTitle
          title="요청 완료"
          onClose={handleClose}
          textColor="#9a9a9a"
          fillColor="#9a9a9a"
        />
        <ConfirmIcon />
        <CompleteWrap>
          <p>{message}</p>
        </CompleteWrap>
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
  background-color: ${({ theme }) => theme.CARD_BG_PRIMARY};
  width: 450px;
  padding: 24px;
  border-radius: 12px;
  gap: 36px;
`;

const ConfirmIcon = styled(confirmSVG)`
  background-color: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const CompleteWrap = styled.div`
  margin: 0 auto;
  > p {
    font-size: 14px;
    color: ${({ theme }) => theme.TEXT_PRIMARY};
    line-height: 22px;
    text-align: center;
    white-space: pre-line;
  }
`;

export default CompleteModal;
