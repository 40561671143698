/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useAlarmDetail,
  useAlarmListStatus,
  useMutationEnteredContent,
} from 'apiHooks/useAlarm';
import { apiCompleted } from 'apis/common';
import { colors } from 'constants/colors';
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { customErrorMsg, cvtAlarmStatus } from 'services/miscFunc';
import { useAppSelect } from 'store/configureStore.hooks';
import styled from 'styled-components';
interface IProps {
  data: any;
  refetch?: () => void;
  detailRefetch: () => void;
}
const AlarmConfirmMessage: React.FC<IProps> = ({
  data,
  detailRefetch,
  refetch,
}) => {
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLTextAreaElement>(null);
  const accountInfo = useAppSelect((state) => state.accountInfo.info);
  const [status, setStatus] = useState(data.status);
  const { data: alarmStatusData = [] } = useAlarmListStatus();
  const { mutateAsync } = useMutationEnteredContent();
  const { data: detailData } = useAlarmDetail(data.id);

  const { accountName: name, loginId } = accountInfo;
  console.log(data);
  const handleClickEnteredContent = async () => {
    if (contentRef.current && contentRef.current.value.length <= 3) {
      customErrorMsg('내용을 4자 이상 등록해주세요.');
      return;
    }
    const enteredPrms = {
      id: data.id,
      status,
      message: contentRef.current!.value,
    };

    const res = await mutateAsync(enteredPrms);

    if (res?.success) {
      apiCompleted();
      refetch?.();
      detailRefetch();
      if (contentRef.current) {
        contentRef.current.value = '';
      }
    }
  };

  return (
    <MessageTable>
      <tbody>
        <tr>
          <th>등록자</th>
          <td>{`${loginId}(${name})`}</td>
          <th>상태</th>
          <td>
            <Select value={status} onChange={(e) => setStatus(e.target.value)}>
              {alarmStatusData.map((item: string) => (
                <option key={item} value={item}>
                  {cvtAlarmStatus(item)}
                </option>
              ))}
            </Select>
          </td>
        </tr>
        <tr>
          <th>내용</th>
          <td colSpan={3}>
            <ContentWrap>
              <textarea ref={contentRef} />
              <ModalSaveBtn onClick={handleClickEnteredContent}>
                등록
              </ModalSaveBtn>
            </ContentWrap>
          </td>
        </tr>
      </tbody>
    </MessageTable>
  );
};

const MessageTable = styled.table`
  position: relative;
  width: 650px;
  th {
    background: #6c6c6c;
    color: ${({ theme }) => theme.WHITE};
    width: 100px;
    height: 43px;
    vertical-align: middle;
  }
  tr {
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
  }
  td {
    vertical-align: middle;
    padding-left: 20px;
  }
  tr:nth-child(1) > td:nth-child(1),
  tr:nth-child(1) > td:nth-child(2) {
    width: 215px;
  }
  tr:nth-child(2) {
    height: 90px;
    textarea {
      width: 100%;
      height: 60px;
      border-radius: 8px;
      padding: 5px;
      font-family: PretendardVariable;
      font-size: 16px;
    }
  }
  textarea {
    width: 450px !important;
  }
`;

const ContentWrap = styled.div`
  max-width: 530px;
  display: flex;
  gap: 20px;
`;

const ModalSaveBtn = styled.button`
  width: 80px;
  height: 60px;
  background: ${({ theme }) => theme.PRIMARY};
  color: ${({ theme }) => theme.WHITE};
  border-radius: 6px;
  border: 0;
  outline: 0;
  font-weight: 500;
`;

const Select = styled.select`
  width: 80%;
  height: 30px;
  text-align: center;
  font-size: 14px;
  outline: none;
  border: none;
  border-radius: 6px;

  > option {
    text-align: center;
  }
`;

export default AlarmConfirmMessage;
