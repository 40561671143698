import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import pageInfoSlice from './modules/pageInfo';
import accountInfoSlice from './modules/account';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import errorSlice from './modules/error';
import confirmSlice from './modules/confirm';
import remoteSlice from './modules/remoteControl';
import themeSlice from './modules/theme';

const persistConfig = {
  key: 'menu',
  storage,
};

const accountConfig = {
  key: 'account',
  storage,
};

const errorConfig = {
  key: 'error',
  storage,
};

const confirmConfig = {
  key: 'confirm',
  storage,
};

const remoteConfig = {
  key: 'remoteControl',
  storage,
};

const themeConfig = {
  key: 'theme',
  storage,
};

const persistedReducer = persistReducer(persistConfig, pageInfoSlice);
const accountReducer = persistReducer(accountConfig, accountInfoSlice);
const errorReducer = persistReducer(errorConfig, errorSlice);
const confirmReducer = persistReducer(confirmConfig, confirmSlice);
const remoteControlReducer = persistReducer(remoteConfig, remoteSlice);
const themeReducer = persistReducer(themeConfig, themeSlice);

const store = configureStore({
  reducer: {
    pageInfo: persistedReducer,
    accountInfo: accountReducer,
    errorData: errorReducer,
    confirmData: confirmReducer,
    remoteControlInfo: remoteControlReducer,
    theme: themeReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
