import { colors } from 'constants/colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useAppSelect } from 'store/configureStore.hooks';
import useSSE from 'hooks/useSSE';
import { Tooltip, Typography } from '@mui/material';
import { ReactComponent as DeleteSVG } from 'assets/img/delete.svg';
import { notifyAllOff } from 'services/libOptions';
import FloatingAlarm from 'components/ModalContents/FloatingAlarmModal';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { DownloadOutlined } from '@mui/icons-material';

interface IProps {
  children: React.ReactNode;
}
const SSEContainer: React.FC<IProps> = ({ children }) => {
  const [noticeCount, setNoticeCount] = useState(0);
  const [downloadNoticeKey, setDownloadNoticeKey] = useState<string>('');
  const { accessToken } = useAppSelect((state) => state.accountInfo.token);
  const { floatAlarmData, noticeApi, contextHolder, sensorExportData } =
    useSSE();

  useEffect(() => {
    if (!floatAlarmData.id) return;
    if (!accessToken) return noticeApi.destroy();

    noticeApi.info({
      message: <FloatHeader>알람</FloatHeader>,
      description: (
        <FloatingAlarm data={floatAlarmData} setNoticeCount={setNoticeCount} />
      ),
      placement: 'bottomRight',
      duration: 30,
      className: 'notice',
      icon: (
        <NotificationsActiveIcon sx={{ color: floatAlarmData.statusColor }} />
      ),
      closeIcon: <DeleteIcon />,
    });
  }, [floatAlarmData.id, accessToken]);

  useEffect(() => {
    if (!sensorExportData.state) return;

    if (sensorExportData.state === 'COMPLETED') {
      if (downloadNoticeKey) {
        noticeApi.destroy(downloadNoticeKey);
        setDownloadNoticeKey('');
      }

      // 다운로드 URL 생성
      const fileUrl = `${process.env.REACT_APP_BE_URL}/download?filename=${sensorExportData.url}`;
      const decodedFilename = decodeURIComponent(sensorExportData.url);

      // Fetch를 사용하여 파일 다운로드
      fetch(fileUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = decodedFilename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error('파일 다운로드 중 오류 발생:', error));
    } else if (sensorExportData.state === 'FAILED') {
      noticeApi.info({
        key: downloadNoticeKey,
        message: <FloatHeader>파일 다운로드</FloatHeader>,
        description: `파일 다운로드 중 오류가 발생하였습니다.`,
        placement: 'bottomLeft',
        duration: 0,
        className: 'file-notice notice',
        icon: <DownloadOutlined />,
      });
    } else {
      let key = downloadNoticeKey;

      if (sensorExportData.state === 'EXTRACTING') {
        key = `download-${Date.now()}`;
        setDownloadNoticeKey(key);
      }

      noticeApi.info({
        key,
        message: <FloatHeader>파일 다운로드</FloatHeader>,
        description: `${sensorExportData.description}`,
        placement: 'bottomLeft',
        duration: 0,
        className: 'file-notice notice',
        icon: <DownloadOutlined />,
      });
    }
  }, [sensorExportData.state]);

  return (
    <>
      {contextHolder}
      {noticeCount > 0 && (
        <Tooltip
          title={
            <Typography sx={{ fontSize: '13px' }}>모든 알람 숨김</Typography>
          }
          placement="left"
          PopperProps={{
            sx: { zIndex: 10001 },
          }}
        >
          <NotificationsOffIcon
            sx={notifyAllOff}
            onClick={() => {
              noticeApi.destroy();
            }}
          />
        </Tooltip>
      )}
      {children}
    </>
  );
};

const FloatHeader = styled.span`
  color: ${({ theme }) => theme.TEXT_PRIMARY}CC;
  font-weight: 700;
`;

const DeleteIcon = styled(DeleteSVG)`
  fill: ${({ theme }) => theme.TEXT_PRIMARY}CC;
  width: 14px;
  height: 14px;
`;

export default SSEContainer;
