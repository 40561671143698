export const initFilteredData = {
  id: -1,
  name: '',
  sensorTypeId: -1,
  displayType: '',
  unit: '',
  device: {
    id: -1,
    name: '',
    code: '',
  },
  description: '',
  hide: false,
  data: [],
  sequence: 0,
  isOperatingSensor: false,
};

export const INIT_REMOTE_DATA = {
  ip: '',
  port: '',
  topic: '',
  username: '',
  password: '',
  protocolType: '',
  name: '',
  code: '',
  description: '',
  options: [],
};

export const INIT_MAPPING_OPTION = {
  key: '',
  name: '',
  unit: '',
  type: '',
  value: '',
};

export const INIT_ALARM_CASE_LIST = {
  caseName: '',
  color: '#000000',
  priority: 0,
  periodMinute: 1,
  isPushSms: false,
  isPushMail: false,
  isPushFloat: false,
};

export const INIT_ALARM_STANDARD = {
  dashboard: {
    id: -1,
    name: '',
    type: '',
    description: '',
    imageUrl: '',
  },
  sensors: [],
};

export const INIT_ALARM_LIST = {
  content: [],
  pageable: {},
  last: false,
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 0,
  sort: {},
  first: false,
  numberOfElements: 0,
  empty: false,
};

export const INIT_ALARM_DETAIL = {
  id: -1,
  dashboardName: '',
  content: '',
  createTime: '',
  type: '',
  status: '',
  checks: [],
};
