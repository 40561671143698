import React, { useEffect, SetStateAction, useState } from 'react';
import { keyframes, styled } from 'styled-components';
import { colors } from 'constants/colors';
import Tag from 'components/Tag/Tag';
import { floatingAlarm } from 'types/alarm-T';
import AlarmDetailModal from './AlarmDetailModal';

interface IProps {
  data: floatingAlarm;
  setNoticeCount: React.Dispatch<SetStateAction<number>>;
}

const FloatingAlarm: React.FC<IProps> = ({ data, setNoticeCount }) => {
  const [onDetailModal, setOnDetailModal] = useState(false);

  useEffect(() => {
    setNoticeCount((prev) => prev + 1);

    return () => setNoticeCount((prev) => prev - 1);
  }, []);

  return (
    <Wrap>
      <ContentWrap>
        <Table>
          <Tbody>
            <TableTr>
              <TableTh>알람 종류</TableTh>
              <TableTd>
                <Tag text={data.type} />
              </TableTd>
            </TableTr>
            <TableTr>
              <TableTh>도메인명</TableTh>
              <TableTd>{data.domainName}</TableTd>
            </TableTr>
            <TableTr>
              <TableTh>설비명</TableTh>
              <TableTd>{data.dashboardName}</TableTd>
            </TableTr>
            <TableTr>
              <TableTh>내용</TableTh>
              <TableTd>{data.content}</TableTd>
            </TableTr>
            <TableTr>
              <TableTh>발생일</TableTh>
              <TableTd>{data.createdTime}</TableTd>
            </TableTr>
          </Tbody>
        </Table>
        <ActionBtn onClick={() => setOnDetailModal(true)}>알람 조치</ActionBtn>
      </ContentWrap>
      {onDetailModal && (
        <AlarmDetailModal data={data} onClose={() => setOnDetailModal(false)} />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 450px;
  z-index: 10000;
  color: ${({ theme }) => theme.TEXT_PRIMARY};
`;

const ContentWrap = styled.div``;

const Table = styled.table`
  margin-left: -36px;
`;

const Tbody = styled.tbody``;

const TableTr = styled.tr`
  font-size: 14px;
`;

const TableTh = styled.th`
  padding: 7px 0;
  text-align: left;
  vertical-align: middle;
  width: 90px;
  font-weight: 700;
`;

const TableTd = styled.td`
  padding: 2px 0;
  text-align: left;
  vertical-align: middle;
  font-weight: 500;
  width: 240px;
`;

const ActionBtn = styled.button`
  margin-top: 14px;
  margin-left: -36px;
  width: 75%;
  height: 24px;
  border-radius: 4px;
  background-color: ${colors.BLUE_BASE};
  border: none;
  color: ${colors.WHITE};
  font-weight: 700;
`;

export default FloatingAlarm;
