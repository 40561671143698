import React, { MouseEvent } from 'react';
import ModalPortal from './ModalPortal';
import { styled } from 'styled-components';
interface IProps {
  children: React.ReactNode;
  onClose?: () => void;
}

const Modal: React.FC<IProps> = ({ children, onClose }) => {
  const handleClickInner = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <ModalPortal>
      <ModalBg onClick={onClose}>
        <ModalContent onClick={handleClickInner}>{children}</ModalContent>
      </ModalBg>
    </ModalPortal>
  );
};

const ModalBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 100;
`;

const ModalContent = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
`;

export default Modal;
