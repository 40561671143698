import { createTheme } from 'constants/colors';
import React from 'react';
import { useAppSelect } from 'store/configureStore.hooks';
import { ThemeProvider } from 'styled-components';
interface IProps {
  children: React.ReactNode;
}
const StyledProvider: React.FC<IProps> = ({ children }) => {
  const themeInfo = useAppSelect((state) => state.theme);
  const theme = createTheme(themeInfo)[themeInfo.data.mode];

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default StyledProvider;
