import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type error = {
  success: boolean;
  error: {
    code: string;
    message: string;
  };
};

const initialState = {
  data: {
    isError: false,
    error: {
      code: '',
      message: '',
    },
  },
};

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setErrorData: (state, action: PayloadAction<error>) => {
      const { success, error } = action.payload;
      const cvtErrorData = {
        isError: !success,
        error: {
          code: error.code,
          message: error.message,
        },
      };

      state.data = { ...cvtErrorData };
    },
    setResetError: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setErrorData, setResetError } = errorSlice.actions;
export default errorSlice.reducer;
