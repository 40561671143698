export enum queryKeys {
  LOGIN = 'login',
  ENTERPRISE = 'enterprise',
  ENTERPRISE_ONE = 'enterprise_one',
  ACCOUNT = 'account',
  DASHBOARD = 'dashboard',
  MAIN = 'main',
  SENSOR = 'sensor',
  REMOTE = 'remote',
  ALARM = 'alarm',
  WEATHER = 'weather',
}
