import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { account } from 'types/account-T';
export type accountInfo = {
  exp: number;
  iat: number;
  sub: string;
  id: number;
  companyId: number;
  role: string;
  accountName: string;
  loginId: string;
  lastLoginTime: string;
};

export const initialState = {
  info: {
    exp: 0,
    iat: 0,
    sub: '',
    id: 0,
    companyId: 0,
    role: '',
    accountName: '',
    loginId: '',
    lastLoginTime: '',
  },
  token: {
    accessToken: '',
    refreshToken: '',
  },
};

const accountInfoSlice = createSlice({
  name: 'accountInfo',
  initialState,
  reducers: {
    setCompanyId: (state, action: PayloadAction<number>) => {
      state.info = { ...state.info, companyId: action.payload };
    },
    setAccountInfo: (state, action: PayloadAction<accountInfo>) => {
      state.info = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.token.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.token.refreshToken = action.payload;
    },
    setAccountInfoReset: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  setCompanyId,
  setAccountInfo,
  setAccessToken,
  setRefreshToken,
  setAccountInfoReset,
} = accountInfoSlice.actions;
export default accountInfoSlice.reducer;
