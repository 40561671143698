/* eslint-disable @typescript-eslint/no-explicit-any */
import Tag from 'components/Tag/Tag';
import React from 'react';
import styled from 'styled-components';
interface IProps {
  data: any;
}
const AlarmEnteredList: React.FC<IProps> = ({ data }) => {
  const contentList = data.checks.sort(
    (a: any, b: any) =>
      new Date(b.createTime).getTime() - new Date(a.createTime).getTime(),
  );
  return (
    <Wrap>
      <Table>
        <thead>
          <TableTr>
            <TableTh>No.</TableTh>
            <TableTh>상태</TableTh>
            <TableTh>등록일</TableTh>
            <TableTh>메시지</TableTh>
            <TableTh>등록자</TableTh>
          </TableTr>
        </thead>
        <tbody>
          {contentList.map((item: any, idx: number) => (
            <TableTr key={item.id}>
              <td>{idx + 1}</td>
              <td>
                <Tag text={item.status} />
              </td>
              <td>{item.createTime}</td>
              <td>{item.message}</td>
              <td>{`${item.accountLoginId}(${item.accountName})`}</td>
            </TableTr>
          ))}
        </tbody>
      </Table>
    </Wrap>
  );
};

const Wrap = styled.div`
  width: 100%;
  height: 180px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 15px;
    margin-right: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #666;

    border-radius: 20px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }
`;

const Table = styled.table`
  width: 100%;

  > thead,
  > tbody {
    width: 100%;
  }
`;

const TableTh = styled.th`
  border-top: 1px solid #9a9a9a;
  position: sticky;
  top: 0;
  background: #6c6c6c;
  color: ${({ theme }) => theme.WHITE};
  vertical-align: middle;
  height: 43px;
`;

const TableTr = styled.tr`
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #9a9a9a;

  > td {
    padding: 10px 0;
    vertical-align: middle;
  }

  > th:nth-child(1),
  > td:nth-child(1) {
    width: 10%;
  }

  > th:nth-child(2),
  > td:nth-child(2) {
    width: 10%;
  }

  > th:nth-child(3),
  > td:nth-child(3) {
    width: 20%;
  }
  > th:nth-child(4),
  > td:nth-child(4) {
    width: 40%;
    white-space: pre-wrap;
    word-break: break-all;
  }

  > th:last-child,
  > td:last-child {
    width: 20%;
    white-space: pre-wrap;
    word-break: break-all;
  }
`;

export default AlarmEnteredList;
