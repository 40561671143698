/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAlarmDetail } from 'apiHooks/useAlarm';
import React from 'react';
import styled from 'styled-components';
interface IProps {
  data: any;
}
const AlarmBasicInfo: React.FC<IProps> = ({ data }) => {
  return (
    <Table>
      <tbody>
        <tr>
          <th>도메인명</th>
          <td>{data.domainName}</td>
        </tr>
        <tr>
          <th>설비명</th>
          <td>{data.dashboardName}</td>
        </tr>
        <tr>
          <th>발생일</th>
          <td>{data.createdTime}</td>
        </tr>
        <tr>
          <th>내용</th>
          <td>{data.content}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  position: relative;
  width: 650px;
  th {
    background: #6c6c6c;
    color: ${({ theme }) => theme.WHITE};
    width: 100px;
    height: 43px;
    vertical-align: middle;
  }
  tr {
    border-top: 1px solid #9a9a9a;
    border-bottom: 1px solid #9a9a9a;
  }
  td {
    vertical-align: middle;
    padding-left: 20px;
  }
`;

export default AlarmBasicInfo;
