import Modal from 'components/common/Modal';
import { colors } from 'constants/colors';
import React, { FormEvent } from 'react';
import styled from 'styled-components';
import { getDefaultWrap } from './common/modalCss';
import ModalButton from './common/ModalButton';
import useDeleteTextCheck from 'hooks/useDeleteTextCheck';
interface IProps {
  img?: string;
  text: string;
  size?: number;
  isLoading?: boolean;
  onClick: () => void;
  onClose: () => void;
  deleteText?: string;
  deleteTextCheck?: boolean;
}
const ConfirmModal: React.FC<IProps> = ({
  img,
  text,
  size,
  isLoading,
  onClick,
  onClose,
  deleteText,
  deleteTextCheck,
}) => {
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onClick();
  };

  const deleteTextCheckResult =
    deleteTextCheck && deleteText ? useDeleteTextCheck(deleteText) : null;
  const { inputText, isValid, handleInputChange } = deleteTextCheckResult || {};

  return (
    <Modal onClose={onClose}>
      <Wrap onSubmit={onSubmit}>
        {img && (
          <ImgWrap $size={size}>
            <img src={img} alt="imgResource" />
          </ImgWrap>
        )}
        <CompleteWrap>
          <p>{text}</p>
          {deleteTextCheck && deleteText && (
            <>
              <br />
              <p>삭제 시 기존 정보를 복구할 수 없습니다.</p>
              <p>
                삭제를 확인하려면 텍스트 박스에 <u>{deleteText}</u>을(를)
                입력하세요.
              </p>
              <input
                placeholder="이곳에 문구를 입력해주세요."
                value={inputText}
                onChange={handleInputChange}
              />
            </>
          )}
        </CompleteWrap>
        <ModalButton
          btnText="확인"
          btnWidth={170}
          isLoading={isLoading}
          onClose={onClose}
          isValid={isValid}
        />
      </Wrap>
    </Modal>
  );
};

const Wrap = styled.form`
  ${getDefaultWrap};
  width: 400px;
  align-items: center;
  font-family: 'Pretendard_R';
  white-space: pre-line;

  > span {
    text-align: center;
  }
`;

const ImgWrap = styled.div<{ $size?: number }>`
  margin: 0 auto;

  > img {
    width: ${({ $size }) => ($size ? `${$size}px` : 'auto')};
    height: ${({ $size }) => ($size ? `${$size}px` : 'auto')};
  }
`;

const CompleteWrap = styled.div`
  margin: 0 auto;
  > p:first-child {
    font-size: 16px;
    font-weight: 600;
  }

  > p {
    font-size: 14px;
    color: ${({ theme }) => theme.TEXT_PRIMARY};
    line-height: 22px;
    text-align: center;
    white-space: pre-line;
  }
  input {
    width: 100%;
    height: 30px;
    padding: 7px;
    margin-top: 20px;
    border: 2px solid ${({ theme }) => theme.CARD_BG_SECONDARY};

    &:focus {
      border: 2px solid ${({ theme }) => theme.PRIMARY};
      transition: 0.7s;
    }
  }
`;

export default ConfirmModal;
