import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

export type Theme = 'darkTheme' | 'lightTheme';
export type ThemeInfo = { data: { mode: Theme; primary: string } };

const initialState: ThemeInfo = {
  data: {
    mode: 'darkTheme',
    primary: '#436ddb',
  },
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setThemeData: (
      state,
      action: PayloadAction<{ mode: Theme; primary: string }>,
    ) => {
      state.data = action.payload;
    },
    setResetTheme: (state) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setThemeData, setResetTheme } = themeSlice.actions;
export default themeSlice.reducer;
