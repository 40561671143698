import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

interface State {
  dashboardId: number;
  isFold: boolean;
  isMainFold: boolean;
  collapsedIds: number[];
}

const initialState: State = {
  dashboardId: -1,
  isFold: false,
  isMainFold: false,
  collapsedIds: [],
};

const pageInfoSlice = createSlice({
  name: 'pageInfo',
  initialState,
  reducers: {
    setDashboardId: (state, action: PayloadAction<number>) => {
      state.dashboardId = action.payload;
    },
    setPageInfoReset: (state) => {
      return initialState;
    },
    setIsFold: (state, action: PayloadAction<boolean>) => {
      state.isFold = action.payload;
    },
    setIsMainFold: (state, action: PayloadAction<boolean>) => {
      state.isMainFold = action.payload;
    },
    setCollapsedIds: (state, action: PayloadAction<number[]>) => {
      state.collapsedIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const {
  setDashboardId,
  setPageInfoReset,
  setIsFold,
  setIsMainFold,
  setCollapsedIds,
} = pageInfoSlice.actions;
export default pageInfoSlice.reducer;
