/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  deleteAlarmCases,
  deleteAlarmRules,
  getAlarmCaseList,
  getAlarmDetail,
  getAlarmList,
  getAlarmListStatus,
  getAlarmListType,
  getAlarmRules,
  patchAllAlarmChecked,
  patchEnteredContentAlarm,
  postAlarmCases,
  putAlarmCases,
  putAlarmRules,
} from 'apis/alarm-rest';
import React, { SetStateAction } from 'react';
import { queryKeys } from 'services/queryKeys';
import { alarmCaseType, sensorAlarmRules } from 'types/alarm-T';

export const useAlarmCase = (
  setTable?: React.Dispatch<SetStateAction<alarmCaseType[]>>,
) => {
  return useQuery([queryKeys.ALARM], getAlarmCaseList, {
    onSuccess: (data: alarmCaseType[]) => setTable?.(data),
  });
};

export const useMutationAlarmCase = () => {
  return useMutation((prms: alarmCaseType[]) => postAlarmCases(prms));
};

export const useMutationPutAlarmCase = () => {
  return useMutation((prms: alarmCaseType[]) => putAlarmCases(prms));
};

export const useMutationDeleteAlarmCase = () => {
  return useMutation((caseId: number) => deleteAlarmCases(caseId));
};

export const useAlarmRules = (dashboardId: number) => {
  return useQuery(
    [queryKeys.ALARM, dashboardId],
    () => getAlarmRules(dashboardId),
    {
      enabled: dashboardId !== -1,
    },
  );
};

export const useMutationAlarmRules = () => {
  return useMutation(
    (prms: { dashboardId: number; prms: sensorAlarmRules[] }) =>
      putAlarmRules(prms),
  );
};

export const useMutationDeleteRules = () => {
  return useMutation(
    (prms: { dashboardId: number; prms: sensorAlarmRules[] }) =>
      deleteAlarmRules(prms),
  );
};

export const useAlarmList = (prms?: any) => {
  return useQuery([queryKeys.ALARM, 'detail-list'], () => getAlarmList(prms));
};

export const useAlarmListType = () => {
  return useQuery([queryKeys.ALARM, 'type'], getAlarmListType);
};

export const useAlarmListStatus = () => {
  return useQuery([queryKeys.ALARM, 'status'], getAlarmListStatus);
};

export const useAlarmDetail = (alarmId: number) => {
  return useQuery([queryKeys.ALARM, 'detail'], () => getAlarmDetail(alarmId));
};

export const useMutationEnteredContent = () => {
  return useMutation((prms: { id: number; status: string; message: string }) =>
    patchEnteredContentAlarm(prms),
  );
};

export const useMutationControlAllAlarms = () => {
  return useMutation((sensorId?: number) => patchAllAlarmChecked(sensorId));
};
