import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import store from 'store/configureStore';
import ReactQueryProvider from './react-query';
import StyledProvider from './styled-components';
import MuiProvider from './mui';

export const persistor = persistStore(store);

const Provider = ({ children }: { children: React.ReactNode }) => {
  return (
    <ReactQueryProvider>
      <ReduxProvider store={store}>
        <StyledProvider>
          <MuiProvider>
            <PersistGate loading={null} persistor={persistor}>
              {children}
            </PersistGate>
          </MuiProvider>
        </StyledProvider>
      </ReduxProvider>
    </ReactQueryProvider>
  );
};

export default Provider;
